import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ChatClientModule } from '@ghale/ngx-chat-client';
import { DateTimeModule } from '@ghale/ngx-datetime';
import { LocationModule } from '@ghale/ngx-location';
import { RttClientModule } from '@ghale/ngx-rtt-client';
import { WebRtcClientModule } from '@ghale/ngx-webrtc-client';
import { WebSocketModule } from '@ghale/ngx-websocket';

import { registerLocaleData } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from '@ghale-ap/app/app-routing.module';
import { AppComponent } from '@ghale-ap/app/app.component';
import { RingingService } from '@ghale-ap/app/services/ringing.service';
import { WebSocketService } from '@ghale-ap/app/services/websocket.service';

export function mapsApiKeyFactory (): string {
  return 'AIzaSyCwVT6m_QY3bh7G0bWSJVtP7J9THhPCrtE';
}

export function HttpLoaderFactory (http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

export function LocaleFactory (): string {
  const langs: Array<string> = [
    'es'
  ];

  return langs.includes(navigator.language) ? navigator.language : 'en';
}

registerLocaleData(localeEs);

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule,
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    DateTimeModule,
    HttpClientModule,
    LocationModule.forRoot(mapsApiKeyFactory),
    WebSocketModule.forRoot(WebSocketService as any),
    TranslateModule.forRoot({
      loader: {
        deps: [
          HttpClient
        ],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory
      }
    }),
    WebRtcClientModule,
    RttClientModule,
    ChatClientModule
  ],
  providers: [
    Keyboard,
    Camera,
    Clipboard,
    StatusBar,
    SplashScreen,
    Diagnostic,
    Geolocation,
    File,
    FileOpener,
    LocationAccuracy,
    Network,
    OpenNativeSettings,
    RingingService,
    // Sim,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useFactory: LocaleFactory }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
