import { Injectable } from '@angular/core';

import { ApUser } from '@ghale-ap/app/definitions/ap-user';
import { CallData } from '@ghale-ap/app/definitions/call-data';
import { Capabilities } from '@ghale-ap/app/definitions/capabilities';
import { Contact } from '@ghale-ap/app/definitions/contact';
import { Languages } from '@ghale-ap/app/definitions/languages';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  get getCallData (): Promise<CallData> {
    return this.storage.get('callData');
  }

  get getAlerting (): Promise<boolean> {
    return this.storage.get('alerting');
  }

  get getCapabilities (): Promise<Array<Capabilities>> {
    return new Promise((resolve) => {
      this.getCallData.then((callData) => {
        if (callData) {
          resolve(callData.capabilities);
        } else {
          resolve(null);
        }
      });
    });
  }

  get getChannel (): Promise<Contact> {
    return new Promise((resolve) => {
      this.getCallData.then((callData) => {
        if (callData) {
          resolve(callData.channel);
        } else {
          resolve(null);
        }
      });
    });
  }

  get getToken (): Promise<string> {
    return this.storage.get('ap.token');
  }

  get getUrl (): Promise<string> {
    return this.storage.get('ap.url');
  }

  get getUser (): Promise<ApUser> {
    return new Promise((resolve) => {
      this.getCallData.then((callData) => {
        if (callData) {
          resolve(callData.user);
        } else {
          resolve(null);
        }
      });
    });
  }

  get getUserFamilyName (): Promise<string> {
    return new Promise ((resolve) => {
      this.getUser.then((user) => {
        if (user) {
          resolve(user.familyName);
        } else {
          resolve(null);
        }
      });
    });
  }

  get getUserGivenNames (): Promise<string> {
    return new Promise ((resolve) => {
      this.getUser.then((user) => {
        if (user) {
          resolve(user.givenNames);
        } else {
          resolve(null);
        }
      });
    });
  }

  get getUserLanguages (): Promise<Languages> {
    return new Promise ((resolve) => {
      this.getUser.then((user) => {
        if (user) {
          resolve(user.languages);
        } else {
          resolve(null);
        }
      });
    });
  }

  constructor (
    private readonly storage: StorageService
  ) {  }

  async enabled (token = true): Promise<boolean> {
    const settings = [];

    settings.push(this.getUrl);
    if (token) {
      settings.push(this.getToken);
    }
    settings.push(this.getCallData);
    settings.push(this.getUser);
    settings.push(this.getUserLanguages);
    settings.push(this.getUserGivenNames);
    settings.push(this.getUserFamilyName);
    settings.push(this.getChannel);
    settings.push(this.getCapabilities);

    return Promise.all(settings)
      .then((values) => {
        let res = true;
        values.forEach((value) => {
          if (!value) {
            res = false;
          }
        });

        return res;
      });
  }

  async isEmpty (): Promise<boolean> {
    return new Promise ((resolve) => {
      this.getCallData.then((callData) => {
        resolve(callData ? false : true);
      });
    });
  }

  setAlerting (alerting: boolean): void {
    this.storage.set('alerting', alerting);
  }

  setCallData (callData: CallData): void {
    this.storage.set('callData', callData);
  }

  setCapabilities (capabilities: Array<Capabilities>): void {
    this.getCallData.then((callData) => {
      if (callData) {
        callData.capabilities = capabilities;
        this.setCallData(callData);
      }
    });
  }

  setChannel (channel: Contact): void {
    this.getCallData.then((callData) => {
      if (callData) {
        callData.channel = channel;
        this.setCallData(callData);
      }
    });
  }

  setToken (token: string): void {
    this.storage.set('ap.token', token);
  }

  setUrl (url: string): void {
    this.storage.set('ap.url', url);
  }

  setUser (user: ApUser): void {
    this.getCallData.then((callData) => {
      if (callData) {
        callData.user = user;
        this.setCallData(callData);
      }
    });
  }

  setUserFamilyName (familyName: string): void {
    this.getCallData.then((callData) => {
      if (callData && callData.user) {
        callData.user.familyName = familyName;
        this.setCallData(callData);
      }
    });
  }

  setUserGivenNames (givenNames: string): void {
    this.getCallData.then((callData) => {
      if (callData && callData.user) {
        callData.user.givenNames = givenNames;
        this.setCallData(callData);
      }
    });
  }

  setUserLanguages (languages: Languages): void {
    this.getCallData.then((callData) => {
      if (callData && callData.user) {
        callData.user.languages = languages;
        this.setCallData(callData);
      }
    });
  }
}
