import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RingingService {

  private readonly _sound: HTMLAudioElement;

  private readonly _key = 'ring-tone';

  constructor (
    private readonly _storage: Storage
  ) {
    this._sound = new Audio('assets/telephone-call-ring.mp3');
    this._sound.loop = true;
    this.getRinging().then((ringing) => {
      if (ringing === null) {
        this.setRinging(true);
      }
    });
  }

  getRinging (): Promise<boolean> {
    return this._storage.get(this._key);
  }

  async play (): Promise<void> {
    if (await this._storage.get(this._key)) {
      this._sound.currentTime = 0;
      this._sound.play();
    }
  }

  setRinging (value: boolean): void {
    this._storage.set(this._key, value);
  }

  stop (): void {
    this._sound.pause();
  }
}
