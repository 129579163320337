import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage;

  constructor(private storage: Storage) {}

  private async getStorage(): Promise<Storage> {
    
    if (!this._storage) {
      const storage = await this.storage.create();
      this._storage = storage;
    }
    return this._storage;
  }

  public set(key: string, value: any) {
    
    this.getStorage().then(storage => storage.set(key, value));
  }

  public get(key: string) {
    
    return this.getStorage().then(storage => storage.get(key));
  }

  
}