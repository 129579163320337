export enum ErrorCode {
  CAMERA_PERMISSION_ALREADY_REQUESTED,
  CAMERA_PERMISSION_NOT_GRANTED,
  CAMERA_SERVICE_DISABLED,
  LOCATION_ENABLE_NOT_GRANTED,
  LOCATION_ENABLE_ALREADY_REQUESTED,
  LOCATION_PERMISSION_ALREADY_REQUESTED,
  LOCATION_PERMISSION_NOT_GRANTED,
  LOCATION_SERVICE_DISABLED,
  FIREFOX_LOCATION_ERROR,
  MICROPHONE_PERMISSION_ALREADY_REQUESTED,
  MICROPHONE_PERMISSION_NOT_GRANTED,
  MICROPHONE_SERVICE_DISABLED,
  CAMERA_TIMEOUT,
  CHANGE_CAMERA,
  LOCATION_TIMEOUT,
  MICROPHONE_TIMEOUT,
  ESTABLISHED_FAIL,
  REESTABLISHED_FAIL,
  DELIVERY_FAIL,
  CLOSE,
  CONNECTION_DROPPED,
  UNKNOWN,
  TIMEOUT,
  SSE_ERROR
}
