import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { CordovaWebSocket } from '../definitions/cordova-websocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor (
    private readonly platform: Platform
  ) { }

  createWebSocket (url: string, token: string): WebSocket | CordovaWebSocket {
    let wsUrl = new URL(url);
    if (token) {
      wsUrl.searchParams.set('token', token);
    }

    if (this.platform.is('cordova')) {
      return new CordovaWebSocket(wsUrl.toString(), {
        Authorization: token
      });
    } else {
      const sourceHost = location.hostname.split('.');

      const targetHost = wsUrl.hostname.split('.').filter((s) => sourceHost.includes(s)).join('.');

      document.cookie = `Authorization=${token}; path=/; domain=${targetHost}; max-age=${5}`;

      return new WebSocket(wsUrl);
    }
  }
}
