import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { Capabilities } from '@ghale-ap/app/definitions/capabilities';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CapabilityService {


  get capabilities (): Promise<Array<Capabilities>> {
    return this.storage.get('capabilities');
  }

  get chatDisabled (): boolean {
    return this._chatDisabled;
  }

  set chatDisabled (value: boolean) {
    this._chatDisabled = value;
  }

  get eiddDisabled (): boolean {
    return this._eiddDisabled;
  }

  set eiddDisabled (value: boolean) {
    this._eiddDisabled = value;
  }

  get eiddProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('eiddProperties');
  }

  get embeddedWebrtcAlertDisplayed (): boolean {
    return this._embeddedWebrtcAlertDisplayed;
  }

  set embeddedWebrtcAlertDisplayed (value: boolean) {
    this._embeddedWebrtcAlertDisplayed = value;
  }

  get embeddedWebrtcDisabled (): boolean {
    return this._embeddedWebrtcDisabled;
  }

  set embeddedWebrtcDisabled (value: boolean) {
    this._embeddedWebrtcDisabled = value;
  }

  get embeddedWebrtcProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('embeddedWebrtcProperties');
  }

  get embeddedWebrtcSettingsDisplayed (): boolean {
    return this._embeddedWebrtcSettingsDisplayed;
  }

  set embeddedWebrtcSettingsDisplayed (value: boolean) {
    this._embeddedWebrtcSettingsDisplayed = value;
  }

  get iotDisabled (): boolean {
    return this._iotDisabled;
  }

  set iotDisabled (value: boolean) {
    this._iotDisabled = value;
  }

  get pendingChatEvents (): number {
    return this._pendingChatEvents;
  }

  set pendingChatEvents (value: number) {
    this._pendingChatEvents = value;
  }

  get pendingEiddEvents (): number {
    return this._pendingEiddEvents;
  }

  set pendingEiddEvents (value: number) {
    this._pendingEiddEvents = value;
  }

  get pendingEmbeddedWebrtcEvents (): number {
    return this._pendingEmbeddedWebrtcEvents;
  }

  set pendingEmbeddedWebrtcEvents (value: number) {
    this._pendingEmbeddedWebrtcEvents = value;
  }

  get pendingIotEvents (): number {
    return this._pendingIotEvents;
  }

  set pendingIotEvents (value: number) {
    this._pendingIotEvents = value;
  }

  get pendingRttEvents (): number {
    return this._pendingRttEvents;
  }

  set pendingRttEvents (value: number) {
    this._pendingRttEvents = value;
  }

  get pendingRttGhaleEvents (): number {
    return this._pendingRttGhaleEvents;
  }

  set pendingRttGhaleEvents (value: number) {
    this._pendingRttGhaleEvents = value;
  }

  get pendingFileExchangeEvents (): number {
    return this._pendingFileExchangeEvents;
  }

  set pendingFileExchangeEvents (value: number) {
    this._pendingFileExchangeEvents = value;
  }

  get pendingSharedSpaceEvents (): number {
    return this._pendingSharedSpaceEvents;
  }

  set pendingSharedSpaceEvents (value: number) {
    this._pendingSharedSpaceEvents = value;
  }

  get pendingSipEvents (): number {
    return this._pendingSipEvents;
  }

  set pendingSipEvents (value: number) {
    this._pendingSipEvents = value;
  }

  get pendingSipsEvents (): number {
    return this._pendingSipsEvents;
  }

  set pendingSipsEvents (value: number) {
    this._pendingSipsEvents = value;
  }

  get pendingWebrtcEvents (): number {
    return this._pendingWebrtcEvents;
  }

  set pendingWebrtcEvents (value: number) {
    this._pendingWebrtcEvents = value;
  }

  get pendingWebrtcNativeEvents (): number {
    return this._pendingWebrtcNativeEvents;
  }

  set pendingWebrtcNativeEvents (value: number) {
    this._pendingWebrtcNativeEvents = value;
  }

  get rttDisabled (): boolean {
    return this._rttDisabled;
  }

  set rttDisabled (value: boolean) {
    this._rttDisabled = value;
  }

  get rttGhaleDisabled (): boolean {
    return this._rttGhaleDisabled;
  }

  set rttGhaleDisabled (value: boolean) {
    this._rttGhaleDisabled = value;
  }

  get fileExchangeDisabled (): boolean {
    return this._fileExchangeDisabled;
  }

  set fileExchangeDisabled (value: boolean) {
    this._fileExchangeDisabled = value;
  }

  get fileExchangeProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('fileExchangeProperties');
  }

  get sharedSpaceDisabled (): boolean {
    return this._sharedSpaceDisabled;
  }

  set sharedSpaceDisabled (value: boolean) {
    this._sharedSpaceDisabled = value;
  }

  get sharedSpaceProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('sharedSpaceProperties');
  }

  get sipDisabled (): boolean {
    return this._sipDisabled;
  }

  set sipDisabled (value: boolean) {
    this._sipDisabled = value;
  }

  get sipProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('sipProperties');
  }

  get sipsDisabled (): boolean {
    return this._sipsDisabled;
  }

  set sipsDisabled (value: boolean) {
    this._sipsDisabled = value;
  }

  get sipsProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('sipsProperties');
  }

  get webrtcAlertDisplayed (): boolean {
    return this._webrtcAlertDisplayed;
  }

  set webrtcAlertDisplayed (value: boolean) {
    this._webrtcAlertDisplayed = value;
  }

  get webrtcDisabled (): boolean {
    return this._webrtcDisabled;
  }

  set webrtcDisabled (value: boolean) {
    this._webrtcDisabled = value;
  }

  get webrtcLoading (): boolean {
    return this._webrtcLoading;
  }

  set webrtcLoading (value: boolean) {
    this._webrtcLoading = value;
  }

  get webrtcRetryToast (): () => Promise<HTMLIonToastElement> {
    return this._webrtcRetryToast;
  }

  set webrtcRetryToast (value: () => Promise<HTMLIonToastElement>) {
    this._webrtcRetryToast = value;
  }

  get webrtcRoomFailed (): boolean {
    return this._webrtcRoomFailed;
  }

  set webrtcRoomFailed (value: boolean) {
    this._webrtcRoomFailed = value;
  }

  get webrtcSettingsDisplayed (): number {
    return this._webrtcSettingsDisplayed;
  }

  set webrtcSettingsDisplayed (value: number) {
    this._webrtcSettingsDisplayed = value;
  }

  get webrtcStreamFailed (): boolean {
    return this._webrtcStreamFailed;
  }

  set webrtcStreamFailed (value: boolean) {
    this._webrtcStreamFailed = value;
  }

  get webrtcNativeAlertDisplayed (): boolean {
    return this._webrtcNativeAlertDisplayed;
  }

  set webrtcNativeAlertDisplayed (value: boolean) {
    this._webrtcNativeAlertDisplayed = value;
  }

  get webrtcNativeDisabled (): boolean {
    return this._webrtcNativeDisabled;
  }

  set webrtcNativeDisabled (value: boolean) {
    this._webrtcNativeDisabled = value;
  }

  get webrtcNativeLoading (): boolean {
    return this._webrtcNativeLoading;
  }

  set webrtcNativeLoading (value: boolean) {
    this._webrtcNativeLoading = value;
  }

  get webrtcNativeProperties (): Promise<{ token: string; uri: string; }> {
    return this.storage.get('webrtcNativeProperties');
  }

  get webrtcNativeRetryToast (): () => Promise<HTMLIonToastElement> {
    return this._webrtcNativeRetryToast;
  }

  set webrtcNativeRetryToast (value: () => Promise<HTMLIonToastElement>) {
    this._webrtcNativeRetryToast = value;
  }

  get webrtcNativeRoomFailed (): boolean {
    return this._webrtcNativeRoomFailed;
  }

  set webrtcNativeRoomFailed (value: boolean) {
    this._webrtcNativeRoomFailed = value;
  }

  get webrtcNativeSettingsDisplayed (): number {
    return this._webrtcNativeSettingsDisplayed;
  }

  set webrtcNativeSettingsDisplayed (value: number) {
    this._webrtcNativeSettingsDisplayed = value;
  }

  get webrtcNativeStreamFailed (): boolean {
    return this._webrtcNativeStreamFailed;
  }

  set webrtcNativeStreamFailed (value: boolean) {
    this._webrtcNativeStreamFailed = value;
  }

  private _chatDisabled = true;

  private _eiddDisabled = true;

  private _embeddedWebrtcAlertDisplayed: boolean;

  private _embeddedWebrtcDisabled = true;

  private _embeddedWebrtcSettingsDisplayed: boolean;

  private _iotDisabled = true;

  private _pendingChatEvents: number;

  private _pendingEiddEvents: number;

  private _pendingEmbeddedWebrtcEvents: number;

  private _pendingIotEvents: number;

  private _pendingRttEvents: number;

  private _pendingRttGhaleEvents: number;

  private _pendingFileExchangeEvents: number;

  private _pendingSharedSpaceEvents: number;

  private _pendingSipEvents: number;

  private _pendingSipsEvents: number;

  private _pendingWebrtcEvents: number;

  private _pendingWebrtcNativeEvents: number;

  private _rttDisabled = true;

  private _rttGhaleDisabled = true;

  private _fileExchangeDisabled = true;

  private _sharedSpaceDisabled = true;

  private _sipDisabled = true;

  private _sipsDisabled = true;

  private _webrtcAlertDisplayed: boolean;

  private _webrtcDisabled = true;

  private _webrtcLoading: boolean;

  private _webrtcRetryToast: () => Promise<HTMLIonToastElement>;

  private _webrtcRoomFailed: boolean;

  private _webrtcSettingsDisplayed: number;

  private _webrtcStreamFailed: boolean;

  private _webrtcNativeAlertDisplayed: boolean;

  private _webrtcNativeDisabled = true;

  private _webrtcNativeLoading: boolean;

  private _webrtcNativeRetryToast: () => Promise<HTMLIonToastElement>;

  private _webrtcNativeRoomFailed: boolean;

  private _webrtcNativeSettingsDisplayed: number;

  private _webrtcNativeStreamFailed: boolean;

  constructor (
    private readonly toastController: ToastController,
    private readonly storage: StorageService
  ) {
    this._embeddedWebrtcSettingsDisplayed = false;
    this._webrtcSettingsDisplayed = 0;
    this._webrtcNativeSettingsDisplayed = 0;
    this._pendingChatEvents = 0;
    this._pendingIotEvents = 0;
    this._pendingRttEvents = 0;
    this._pendingRttGhaleEvents = 0;
    this._pendingFileExchangeEvents = 0;
    this._pendingSharedSpaceEvents = 0;
    this._pendingEiddEvents = 0;
    this._pendingSipEvents = 0;
    this._pendingSipsEvents = 0;
    this._pendingWebrtcEvents = 0;
    this._pendingWebrtcNativeEvents = 0;
    this._pendingEmbeddedWebrtcEvents = 0;
  }

  hideEmbeddedWebrtcSettings (): void {
    this._embeddedWebrtcSettingsDisplayed = false;
  }

  setCapabilities (value: Array<Capabilities>): void {
    this.storage.set('capabilities', value);
  }

  setEiddProperties (uri: string, token: string): void {
    this.storage.set('eiddProperties', {
      token,
      uri
    });
  }

  setEmbeddedWebrtcProperties (uri: string, token: string): void {
    this.storage.set('embeddedWebrtcProperties', {
      token,
      uri
    });
  }

  setFileExchangeProperties (uri: string, token: string): void {
    this.storage.set('fileExchangeProperties', {
      token,
      uri
    });
  }

  setSharedSpaceProperties (uri: string, token: string): void {
    this.storage.set('sharedSpaceProperties', {
      token,
      uri
    });
  }

  setSipProperties (uri: string): void {
    this.storage.set('sipProperties', {
      uri
    });
  }

  setSipsProperties (uri: string): void {
    this.storage.set('sipsProperties', {
      uri
    });
  }

  setWebrtcNativeProperties (uri: string, token: string): void {
    this.storage.set('webrtcNativeProperties', {
      token,
      uri
    });
  }

  showEmbeddedWebrtcSettings (): void {
    this._embeddedWebrtcSettingsDisplayed = true;
  }

  async showOnceToast (toast: () => Promise<HTMLIonToastElement>): Promise<HTMLIonToastElement> {
    return new Promise((resolve) => {
      this.toastController.dismiss()
        .then()
        .catch()
        .finally(() => {
          resolve(toast());
        });
    });
  }

  showWebrtcSettings (): void {
    window.clearTimeout(this._webrtcSettingsDisplayed);
    this._webrtcSettingsDisplayed = window.setTimeout(() => {
      this._webrtcSettingsDisplayed = 0;
    }, 6_000);
  }

  showWebrtcNativeSettings (): void {
    window.clearTimeout(this._webrtcNativeSettingsDisplayed);
    this._webrtcNativeSettingsDisplayed = window.setTimeout(() => {
      this._webrtcNativeSettingsDisplayed = 0;
    }, 6_000);
  }
}
