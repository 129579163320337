import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpError } from '../definitions/http-error';
import { Token } from '../definitions/token';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  get getActive (): Promise<boolean> {
    return this.storage.get('am.active');
  }

  get getToken (): Promise<Token> {
    return this.storage.get('am.token');
  }

  get getAmToken (): Promise<string> {
    return this.storage.get('am.amToken');
  }

  get getUrl (): Promise<string> {
    return this.storage.get('am.url');
  }

  private _amToken: string;

  private _url: string;

  constructor (
    private readonly storage: StorageService,
    private readonly http: HttpClient
  ) {
    this.getAmToken.then((amToken) => this._amToken = amToken);
    this.getUrl.then((url) => this._url = url);
  }

  async enabled (): Promise<boolean> {
    const settings = [];

    settings.push(this.getUrl);
    settings.push(this.getAmToken);

    return Promise.all(settings)
      .then((values) => {
        let res = true;
        values.forEach((value) => {
          if (!value) {
            res = false;
          }
        });

        return res;
      });
  }

  generateToken (): Observable<Token> {
    return this.http.post<Token>(`${this._url}/token/generate`, null, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: this._amToken
      })
    });
  }

  register (msisdn: string): Observable<string> {
    return this.http.post<string>(`${this._url}/register`, JSON.stringify({msisdn}), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );
  }

  setActive (active: boolean): void {
    this.storage.set('am.active', active);
  }

  setAmToken (amToken: string): void {
    this.storage.set('am.amToken', amToken);
    this._amToken = amToken;
  }

  setToken (token: Token): void {
    this.storage.set('am.token', token);
  }

  setUrl (url: string): void {
    url = url[url.length - 1] === '/' ? url.slice(0, -1) : url;
    this.storage.set('am.url', url);
    this._url = url;
  }

  validate (msisdn: string, code: string): Observable<Token> {
    return this.http.post<Token>(`${this._url}/validate`, JSON.stringify({msisdn, code}), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );
  }

  private readonly handleError = (error: HttpErrorResponse): Observable<never> => {
    if (!error.status) {
      return throwError({
        error: HttpError.NAME_NOT_RESOLVED,
        status: error.status,
        body: error.error
      });
    }
    if (error.error instanceof ErrorEvent) {
      return throwError({
        error: HttpError.CLIENT_ERROR,
        status: error.status,
        body: error.error
      });
    } else {
      return throwError({
        error: HttpError.SERVER_ERROR,
        status: error.status,
        body: error.error
      });
    }
  };
}
