import EventTarget from 'event-target';

declare var CordovaWebsocketPlugin: any;

export class CordovaWebSocket {

  get readyState (): number {
    return this.cordovaConnected ? WebSocket.OPEN : WebSocket.CLOSED;
  }

  target = new EventTarget();

  private cordovaConnected = false;

  private socketId: string;

  constructor (
    url: string,
    headers: any = { }
  ) {
    if (this.socketId) {
      CordovaWebsocketPlugin.wsClose(this.socketId, 1000, 'Restarting connection.');
    }
    CordovaWebsocketPlugin.wsConnect({
      headers,
      pingInterval: 60000,
      timeout: 5000,
      url
    },
    (recvEvent) => {
      switch (recvEvent.callbackMethod) {
        case 'onMessage':
          console.log('Websocket received message: ', recvEvent.message);
          this.target.dispatchEvent(new MessageEvent('message', { data: recvEvent.message }));
          break;
        case 'onClose':
          console.log('Websocket received close: ', recvEvent.reason ? recvEvent.reason : recvEvent.exception);
          this.cordovaConnected = false;
          this.close();
          this.target.dispatchEvent(new CloseEvent('close', {
            code: recvEvent.code,
            reason: recvEvent.reason,
            wasClean: recvEvent.code === 1000
          }));
          break;
        case 'onFail':
          console.log('Websocket received fail: ', recvEvent.exception);
          this.cordovaConnected = false;
          this.target.dispatchEvent(new ErrorEvent('error', recvEvent));
          break;
        default:
      }
    },
    (success) => {
      console.log(`Websocket connected with ${url}`);
      this.cordovaConnected = true;
      this.socketId = success.webSocketId;
      this.target.dispatchEvent(new Event('open'));
    },
    (error) => {
      console.log(`Error connecting websocket with ${url}:`, error);
      this.cordovaConnected = false;
      this.target.dispatchEvent(new ErrorEvent('error', error));
    });
  }

  addEventListener (name: string, handler: (e: any) => void): void {
    this.target.addEventListener(name, handler);
  }

  close (): void {
    CordovaWebsocketPlugin.wsClose(this.socketId, 1000, 'Closing connection.');
  }

  removeEventListener (name: string, handler: (e: any) => void): void {
    this.target.removeEventListener(name, handler);
  }

  send (text: string): void {
    console.log('Sending websocket message: ', text);
    CordovaWebsocketPlugin.wsSend(this.socketId, text);
  }
}
